<template>
    <div class="addPracticalSurvey">
        <rxNavBar title="添加实勘"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <div class="content">
            <!--            上传图片区域-->
            <div class="part">
                <div>
                    <span class="part-subtitle"></span>
                    <span
                        class="part-inputpart-row-right part-inputpart-row-graytext">{{addTime | formatDateTime}}</span>
                </div>
                <!--                上传图片-->
                <div class="part-inputpart part-uploadpic">
                    <div class="part-inputpart-row-enablenon">
                        <span class="part-inputpart-row-header">上传照片</span>
                        <span class="part-inputpart-row-graytext">(最多10张）</span>
                    </div>
                    <!--                    图片遍历-->
                    <div class="part-uploadpic-content">
                        <div class="part-uploadpic-module" v-for="(item,index) in picList" :key="index">
                            <div class="part-cardpic-module">
                                <div class="delete-photo" @click="deleteItem(index)">x
                                </div>
                                <div class="part-uploadpic-module-pic">
                                    <img  :src="item.content">

                                </div>

                                <div @click="clickTypeShow(index)" class="test">

                                    <span class="part-uploadpic-text">{{0==item.message.length ? typeArr[0].dictionaryTitle : item.message.dictionaryTitle}}</span>

                                    <img :class=" (typeModuleId==index&&isTypeChooseShow)  ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                                        src="../../../assets/images/triangle.png">
                                </div>

                            </div>
                        </div>
                        <!--                        上传图片按钮-->
                        <van-uploader class="part-uploadpic-van-uploader" :preview-image="false" v-model="picList" multiple
                            :after-read="afterReadTest" upload-icon="plus" :max-count="10"></van-uploader>
                    </div>
                </div>
                <div>
                    <span class="part-inputpart-row-redtext">*</span>
                    <span class="part-inputpart-row-graytext">只能上传房屋图片，不能包含有文字、数字、网址、名字等。</span>
                </div>

            </div>
            <!--            实勘描述区域-->
            <div class="part">
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span
                                :class=" 0!=explorationDescription.trim().length ? 'content-have' : 'content-none' "></span>
                            <span class="part-inputpart-row-header">实勘描述</span>
                        </div>
                        <textarea v-model="explorationDescription" placeholder="请描述实勘情况"></textarea>
                    </div>
                </div>
            </div>
            <div @click="saveData" class="part part-button"
                :class="0==explorationDescription.trim().length  ? '' : 'part-button-enabled' ">保存</div>
        </div>
        <!--房间区域类型下拉框-->
        <van-popup v-model="isTypeChooseShow" position="bottom">
            <van-picker show-toolbar  :columns="typeArr" value-key="dictionaryTitle"
                        @cancel="isTypeChooseShow = false" @confirm="selectTypeOption" />
        </van-popup>
    </div>
</template>

<script>
	import Cookies from 'js-cookie';
    import {
        NavBar,
        Uploader,
        Loading,
        Overlay,
        Picker,
        Popup,
    } from 'vant'
    import {
        base64Upload,
        explorationSave,
        queryBaseData,
        queryExploration
    } from "../../../getData/getData";
    import {
        getStaffId, globaluserId,dealImage,
        responseUtil
    } from "../../../libs/rongxunUtil";
    import rxNavBar from '../../../components/rongxun/rx-navBar/navBar';


    export default {
        name: "AddPracticalSurvey",

        components: {
            [NavBar.name]: NavBar,
            [Uploader.name]: Uploader,
            [Loading.name]: Loading,
            [Overlay.name]: Overlay,
            [Picker.name]:Picker,
            [Popup.name]:Popup,
            rxNavBar
        },

        data() {
            return {
                //loading加载
                loadingFlag:false,
                //当前图片类型id
                typeModuleId: '',
                //是否显示类型选项
                isTypeChooseShow: false,
                oldType: '',
                staffName: '',
                addTime: new Date(),
                picList: [],
                //描述
                explorationDescription: '',
                typeArr: []
            }
        },

        mounted: function () {
            this.id = this.$route.query.id
            this.houseType = this.$route.query.houseType
            // 初始化
            this.initData()
            this.initDicData();
        },

        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer) //在Vue实例销毁前，清除我们的定时器
            }
        },

        filters: {
            formatDateTime(value) {
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                let h = date.getHours();
                h = h < 10 ? "0" + h : h;
                let m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
                let s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
                return y + '-' + MM + '-' + d
            }
        },
        computed: {

        },
        methods: {

            //返回上一层
            leftReturn() {
                this.$router.go(-1);
            },

            afterReadTest(file){
                // this.loadingFlag = true;
                debugger
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    // this.afterRead(file);
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                debugger
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                base64Upload(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        file.content = response.data.data.path
                        // that.loadingFlag = false
                        if(i == fileLength){
                            that.loadingFlag = false
                        }
                    })
                })
            },

            // afterRead(file,i,fileLength) {
            //     debugger
            //     let that = this
            //     let initData = {
            //         base64: file.content
            //     }
            //     that.loadingFlag = true,
            //     base64Upload(initData).then(function (response) {
            //         responseUtil.dealResponse(that, response, () => {
            //             file.content = response.data.data.path
            //             console.log(that.picList)
            //             if(i == fileLength){
            //                 that.loadingFlag = false
            //             }
            //             // that.loadingFlag = false
            //         })
            //     })
            // },

            deaaalImage(file,base64, w, callback,i,fileLength) {
                let that = this;
                var newImage = new Image();
                var quality = 0.6;    //压缩系数0-1之间
                newImage.src = base64;
                newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
                var imgWidth, imgHeight;
                newImage.onload = function () {
                    imgWidth = this.width;
                    imgHeight = this.height;
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    // if (Math.max(imgWidth, imgHeight) > w) {
                    //     if (imgWidth > imgHeight) {
                    //         canvas.width = w;
                    //         canvas.height = w * imgHeight / imgWidth;
                    //     } else {
                    //         canvas.height = w;
                    //         canvas.width = w * imgWidth / imgHeight;
                    //     }
                    // } else {
                        canvas.width = imgWidth;
                        canvas.height = imgHeight;
                        // if(that.xishu == ''){
                            that.xishu = 0.6
                        // }
                        quality = Number(that.xishu);
                    // }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                    var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
                    // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                    // while (base64.length / 1024 > 150) {
                    // 	quality -= 0.01;
                    // 	base64 = canvas.toDataURL("image/jpeg", quality);
                    // }
                    // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                    // while (base64.length / 1024 < 50) {
                    // 	quality += 0.001;
                    // 	base64 = canvas.toDataURL("image/jpeg", quality);
                    // }
                    callback(file,base64,i,fileLength);//必须通过回调函数返回，否则无法及时拿到该值
                }
             },


            //删除图片 （暂不用）
            deleteItem(index){
                //console.log(index)
                this.picList.splice(index,1)
            },

            //显示类型选择
            clickTypeShow(index) {
                // console.log(index)
                this.typeModuleId = index;
                this.isTypeChooseShow = true
                this.oldType = this.picList[index].message
            },

            //隐藏类型选择
            clickTypeHidden() {
                this.picList[this.typeModuleId].message = this.oldType
                this.isTypeChooseShow = false
            },

            //确定类型选择
            clickTypeConfirm() {
                this.isTypeChooseShow = false
            },

            //选择类型
            selectTypeOption(item) {
                console.log(this.typeArr)
                this.picList[this.typeModuleId].message = item;
                this.isTypeChooseShow = false
            },

            // 页面初始化
            initData: function () {
                var that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.user_id = globaluserId()
                initData.id = that.id
                queryExploration(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    })
                })
                // that.staffName=that.$route.query.staffName
				that.staffName=Cookies.get('user')
				console.log(that.staffName)
                this.timer = setInterval(() => {
                    that.addTime = new Date() //更新数据addTime
                }, 1000)
            },

            // 保存数据
            saveData: function () {
                var that = this
                let initData = {}
                if (0 == that.explorationDescription.trim().length) return
                //潜在资源id
                initData.id = that.id
                //描述
                initData.explorationDescription = that.explorationDescription
                initData.addStaff_id = getStaffId()
                initData.staff_id = getStaffId()
                initData.addTime = that.addTime
                initData.picList = []
                for (var i = 0; i < that.picList.length; i++) {
                    var a = that.picList[i].message.id
                    if(a==undefined){
                        a=70
                    }
                    var pic = {
                        type: a,
                        path: that.picList[i].content
                    }
                    initData.picList.push(pic)
                }
                console.log(initData)
                explorationSave(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(that.follow)
                        that.leftReturn()
                        responseUtil.alertMsg(that,'保存成功')
                    })
                })
            },
            // 保存完成后跳转初始化页面
            // practicalSkip(){
            //     this.$router.push({
            //         name : 'practicalExplorationInfo',
            //         query : {
            //             id : this.$route.query.id,
            //             follow : this.follow,
            //             idList : this.idList,
            //             houseType : this.houseType
            //         }
            //     })
            // },
            initDicData: function () {
                var that = this
                let initData = {}
                initData.dbName = ['explorationType']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data)
                        that.typeArr = response.data.data.explorationType
                    })
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .content {
        /*margin-top: 5px;*/
    }

    .part {
        margin: 15px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-uploadpic {
        background-color: white;
        width: 100%;
    }

    .part-button {
        height: 40px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 35px;
        margin-bottom: 35px;
        width: 92%;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25px;
        background-color: #ffffff;
        margin-top: 8px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 6px;
    }

    .part-inputpart-textarea textarea {
        height: 100px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }


    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-uploadpic-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .part-uploadpic-module {
        margin: 10px 25px 10px 10px;
    }

    .part-uploadpic-text {
        font-size: 15px;
        font-weight: 900;
        /*width:50px;*/
    }

    .part-uploadpic-module-pic {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        margin-bottom: 5px;
        overflow: hidden;
        background-color: #e1eaec;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            /*flex-shrink: 0 ;*/
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
        }

    }


    .part-uploadpic-van-uploader {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .part-inputpart-dropDownListMask {
        background: rgba(0, 0, 0, 0.8);
        position: fixed;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    .part-inputpart-dropDownListContent {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 300;
        background-color: white;
        height: 42%;
    }

    .part-inputpart-dropDownList {
        height: 70%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .part-inputpart-dropDownList div:first-child {
        margin-top: 180px;
    }

    .part-inputpart-dropDownList-button {
        height: 40px;
        border-radius: 10px;
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
        margin: 15px;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 45px;
        background-color: white;
        font-size: 15px;
        width: 92%;
        border-bottom: 1px #d8d8d8 solid;
        color: #999999;
        font-weight: 900;
    }

    .part-inputpart-row-enablenon {
        margin-top: 15px;
        margin-left: 12px;
    }

    .part-inputpart-row-graytext {
        font-size: 12px;
        color: #d8d8d8;
    }

    .part-inputpart-row-normaltext {
        color: black;
    }

    .part-inputpart-row-redtext {
        font-size: 18px;
        color: #ff5d3b;
        position: relative;
        bottom: -4.5px;
        margin-left: 5px;
    }

    .part-inputpart-row-right {
        margin-top: 12px;
        float: right;
    }

    .part-inputpart-row-right-downArrow {
        position: absolute;
        top: 6px;
        right: -12px;
        width: 9px;
    }

    .part-inputpart-row-right-upArrow {
        position: absolute;
        top: 6px;
        right: -12px;
        width: 9px;
        transform: rotateX(180deg);
    }

    .content-have {
        padding: 3px;
        margin: 0 8px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 8px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .part-cardpic-module {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: center;*/
    }

    .test {
        position: relative;
        height: 20px;
        /*width: 40px;*/
    }

    /*删除图片*/
    .delete-photo {
        /*margin-top: -110px;*/
        /*margin-left: 65px;*/
        position: absolute;
        /*left: 66px;*/
        /*top: 1px;*/
        right: 0;
        width: 9px;
        line-height: 10px;
        height: 10px;
        padding-bottom: 3px;
        border-radius: 0 8px 0 18px;
        background-color: black;
        color: white;
        font-size: 12px;
        /*border: 1px solid #999;*/
        border: none;
        /*display: flex;*/
        justify-content: center;
        /*padding-bottom: 13px;*/
        /*align-items: center;*/
        padding-left: 4px;
        /*padding-bottom: 3px;*/
        div{
            width: 8px;
            border: 0.5px solid red;
        }
    }
</style>
